<template>
  <main class="login">
    <div class="container">
      <h1><img src="/img/logo-black-gold.png" alt="Logo" /></h1>
      <form @submit.prevent="signin">
        <div class="username">
          <label for="email">{{ $t("overall.email") }}</label>
          <input id="email" placeholder="info@uniqestates.be" type="email" name="email" v-model="email" />
        </div>
        <div class="password">
          <label for="password">{{ $t("overall.password") }}</label>
          <input id="password" placeholder="•••••••••" type="password" name="password" v-model="password" />
        </div>
        <div class="error" v-if="error">{{ error }}</div>
        <button class="cta">{{ $t("overall.login") }}</button>
      </form>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      error: null,
      loading: false,
    };
  },
  methods: {
    async signin() {
      console.log("signin now !!!");
      try {
        console.log("Trying to log user");
        const response = await this.$axios.post("login/check", {
          email: this.email,
          password: this.password,
        });

        if (response.data) {
          console.log("Logged successfully");
          this.$cookies.set("jkt", response.data.jkt);
          this.$cookies.set("usr_lang", "en");
          this.$store.commit("TOGGLE_MENU", true);
          this.$axios.defaults.headers.common["Authorization"] = response.data.jkt;
        } else {
          console.log("Error will logging 1", response);
          this.error = "Nom d'utilisateur ou mot de passe incorrect";
        }
      } catch (error) {
        console.log("Fatal error will login 2", error.response);
        this.error = "Nom d'utilisateur ou mot de passe incorrect";
      }

      // Setup complete profile
      try {
        console.log("Will try to get the full profile now");
        const response = await this.$axios.get("/api/users/profile");
        await this.$store.dispatch("setupProfile", response.data);
        await this.$store.dispatch("setupLanguages");
        this.$router.push({ name: "properties" });
      } catch (error) {
        console.log("Fatal error will getting the full profile - call to /api/users/profile failed", error.response);
      }
    },
  },
});
</script>

<style scoped></style>
